<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="formatedData()"
      line-numbers
      :search-options="{
        enabled: true,
      }"
      :pagination-options="{
        enabled: true,
      }"
      :theme="isDarkMode()"
    />
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import { mapActions, mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

export default {
  name: 'Denied',
  components: {
    VueGoodTable,
  },
  data() {
    return {
      columns: [
        {
          label: 'Sender Names',
          field: 'sender',
        },
        { label: 'Username', field: 'user_name' },
        {
          label: 'Message',
          field: 'message',
        },
        {
          label: 'Date Created',
          field: 'created_at',
        },
        {
          label: 'Date Updated',
          field: 'updated_at',
        },
      ],
    };
  },

  created() {
    this.fetchSenderNames(3);
  },

  computed: {
    ...mapGetters('admin', ['senderNames']),
    ...mapState('coreuiStore', ['darkMode']),
  },

  methods: {
    ...mapActions('admin', ['fetchSenderNames']),

    formatedData() {
      const dayJs = dayjs.extend(advancedFormat);
      return this.senderNames.map((names) => ({
        ...names,
        created_at: dayJs(names.created_at).format('Do MMM YYYY'),
        updated_at: dayJs(names.updated_at).format('Do MMM YYYY'),
      }));
    },

    isDarkMode() {
      return this.darkMode ? 'nocturnal' : null;
    },
  },
};
</script>
